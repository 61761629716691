import { momentHelper } from './momentHelper';

const required = value => (value ? undefined : 'Обязательное поле!');
const exceptions = (value, allValues, props) => {
  const { exceptions } = props.cabinetVehicles;
  if (exceptions.indexOf(value) !== -1) {
    return 'Email уже был использован!';
  }
  return undefined;
};

const maxApplicationWeight = (value, allValues, props) => {
  let ptsAllowedSum = 0;
  if (!props.userApplications.currentItem) {
    return undefined;
  }
  const { vehicle } = props.userApplications.currentItem;
  const { trailers } = props.userApplications.currentItem;

  if (vehicle) {
    ptsAllowedSum += parseFloat(vehicle.pts_max_weight);
  }
  if (trailers) {
    for (let i = 0; i < trailers.length; i++) {
      ptsAllowedSum += parseFloat(trailers[i].pts_max_weight);
    }
  }

  return value && value > ptsAllowedSum && !allValues.is_penalty
    ? `Общая масса не может превышать максимальную разрешенную массу автопоезда, ${ptsAllowedSum} тонн`
    : undefined;
};

const maxLength10 = value => value.toString().length <= 10 ? undefined : 'Должно быть меньше 10 цифр'

export const maxLength = max => value =>
  value && value.length > max ? `Должно быть ${max} символов или меньше` : undefined;
const maxLength3 = maxLength(3);
const maxLength4 = maxLength(4);
const maxLength40 = maxLength(40);
const maxLength60 = maxLength(60);
const maxLength100 = maxLength(100);
const maxLength150 = maxLength(150);

export const minLengthText = min => {
  if (min === 1) return `Должен быть ${min} символ или больше`;
  if (min > 1 && min < 5) return `Должно быть ${min} символа или больше`;
  return `Должно быть ${min} символов или больше`;
};

const minLength = min => value => (value && value.length < min ? minLengthText(min) : undefined);

const minLength2 = minLength(2);
const minLength4 = minLength(4);
const minLength6 = minLength(6);
const minLength8 = minLength(8);

const exactLength = length => value =>
  value && value.length !== length ? `Должно быть ровно ${length} символов` : undefined;
const exactLength4 = exactLength(4);
const exactLength6 = exactLength(6);
const exactLength9 = exactLength(9);
const exactLength10 = exactLength(10);
const exactLength12 = exactLength(12);
const exactLength20 = exactLength(20);

const exactValue = exact => value =>
  value && value != exact ? `Должно быть равно ${exact}` : undefined;
const exactValue1 = exactValue(1);
const exactValue2 = exactValue(2);

const number = value => (value && isNaN(Number(value)) ? 'Должно быть числом' : undefined);

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
    ? 'Неверный формат email адреса'
    : undefined;

const alphaNumeric = value =>
  value && /[^a-zA-ZА-Яа-я0-9 ]/i.test(value) ? 'Может состоять только из цифр и букв' : undefined;

const phoneNumber = value =>
  value && !/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/i.test(value)
    ? 'Неверный формат номера телефона!'
    : undefined;

const phoneRus = value =>
  value && !/^[0-9]{10}$/i.test(value) ? 'Неверный формат номера телефона!' : undefined;

const passwordsMatch = (value, allValues) =>
  value !== allValues.password ? 'Пароли не совпадают!' : undefined;

const maxValue = max => value =>
  value && parseFloat(value) > max ? `Должно быть меньше или равно ${max}` : undefined;
const maxValue1 = maxValue(1);
const maxValue2 = maxValue(2);
const maxValue3 = maxValue(3);
const maxValue20 = maxValue(20);
const maxValue50 = maxValue(50);
const maxValue100 = maxValue(100);
const maxValue150 = maxValue(150);
const maxValue1000 = maxValue(1000);

const minValue = min => value =>
  value && parseFloat(value) <= min ? `Должно быть больше ${min}` : undefined;
const minValue0 = minValue(0);
const minValue1 = minValue(1);

const dateLaterToday = value => {
  const nowDate = momentHelper.getNowDateTime();
  const valueDate = momentHelper.getDateFromPicker(value);
  return nowDate > valueDate ? 'Дата не может быть ранее сегодняшнего дня!' : undefined;
};

const dateEarlier3Month = value => {
  const MAX_DIFF = 1000 * 60 * 60 * 24 * 90;
  let nowDate = new Date(momentHelper.getNowDateTime());
  const threeMonthEarlierDate = nowDate.setMonth(nowDate.getMonth()-3);

  const valueDate = momentHelper.getDateFromPicker(value);
  return valueDate <= threeMonthEarlierDate
    ? 'Дата начала действия разрешения должна быть не позднее чем через 3 месяца от сегодняшней даты!'
    : undefined;
};

const dateLaterThanStartDate = (value, values) => {
  const startValue = values.start_date;
  const startDate = momentHelper.getDateFromPicker(startValue);
  const valueDate = momentHelper.getDateFromPicker(value);

  if (valueDate < startDate) {
    return 'Конечная дата не может быть ранее начальной';
  }

  return undefined;
};

const datesInterval9MonthFromStart = (value, values) => {
  return datesIntervalNMonthFromStart(value, values, 9);
};
const datesInterval3MonthFromStart = (value, values) => {
  return datesIntervalNMonthFromStart(value, values, 3);
};
const datesIntervalNMonthFromStart = (value, values, N) => {

  const startValue = values.start_date;
  const startTime = momentHelper.getDateFromPicker(startValue);
  const valueTime = momentHelper.getDateFromPicker(value);
  if (valueTime < startTime) {
      return 'Конечная дата не может быть больше начальной';
  }
  let startDate = new Date(startTime);
  const MAX_TIME = startDate.setMonth(startDate.getMonth() + N);

  if (valueTime > MAX_TIME) {
    return 'Срок не может превышать ' + N + ' месяцев!';
  }
  return undefined;
};

const datesInterval10DaysFromStart = (value, values) => {
  const MAX_DIFF = 1000 * 60 * 60 * 24 * 10;

  const startValue = values.start_date;
  const startDate = momentHelper.getDateFromPicker(startValue);
  const valueDate = momentHelper.getDateFromPicker(value);

  if (valueDate < startDate) {
    return 'Конечная дата не может быть больше начальной';
  }

  const diff = valueDate - startDate;
  if (diff >= MAX_DIFF) {
    return 'Срок не может превышать 10 дней!';
  }
  return undefined;
};

const requiredWithout = (fieldName, value, values) => {
  if (!value && !values[fieldName]) {
    return 'Не может быть пустым';
  }
  return undefined;
};

const requiredPts = (value, values) => {
  if (values.sts_number) {
    return undefined;
  }
  if (!value) {
    return 'Не может быть пустым!';
  }
  if (value.length > 15 && value.length < 3) {
    return 'Должен содержать от 3 до 15 символов';
  }
  return undefined;
  // return requiredWithout('sts_number', value, values);
};
const requiredSts = (value, values) => {
  if (values.pts_number) {
    return undefined;
  }
  if (!value) {
    return 'Не может быть пустым!';
  }
  if (value.length > 15 && value.length < 3) {
    return 'Должен содержать от 3 до 15 символов';
  }
  return undefined;
  // return requiredWithout('pts_number', value, values);
};

export const fieldValidators = {
  required,
  exceptions,
  maxApplicationWeight,

  maxLength3,
  maxLength4,
  maxLength10,
  maxLength40,
  maxLength60,
  maxLength100,
  maxLength150,

  minLength2,
  minLength4,
  minLength6,
  minLength8,

  exactLength4,
  exactLength6,
  exactLength9,
  exactLength10,
  exactLength12,
  exactLength20,

  exactValue1,
  exactValue2,

  maxValue20,
  maxValue50,
  maxValue100,
  maxValue150,
  maxValue1000,
  minValue0,
  minValue1,
  maxValue1,
  maxValue2,
  maxValue3,

  number,
  email,
  alphaNumeric,
  phoneNumber,
  phoneRus,
  passwordsMatch,

  dateLaterToday,
  dateEarlier3Month,
  dateLaterThanStartDate,
  datesInterval3MonthFromStart,
  datesInterval9MonthFromStart,
  datesInterval10DaysFromStart,
  // datesInterval3MonthFromFinish,

  requiredPts,
  requiredSts
};
