import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Field, Form, Formik} from 'formik';
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import MaterialSelect from "../../admin/quickApplications/CreateQuickApplication/components/MaterialSelect";
import {apvgkReportsService} from "../../../services/apvgkReportsService";
import {history} from "../../../helpers/history";
import {urlHelper} from "../../../helpers/urlHelper";
import {roleConstants} from "../../../constants/roleConstants";
import ReactSelect from "react-select";


const GRZ_TYPE = 2;
const GrzSaveDialog = ({report}) => {

    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);

    const [imageDataURL, setImageDataURL] = useState(null);
    const [file, setFile] = useState(null);

    const result = (report.grz_from_post_result) || {};
    const finds = result.hasOwnProperty('find') ? result.find : result;
    const list = finds.map(v=> ({value :v.vRegno, label :v.vRegno}));
    const onPaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const imageFile = items[i].getAsFile();
                processImage(imageFile);
            }
        }
    };

    const processImage = (imageFile) => {
        setFile(imageFile);
        const reader = new FileReader();
        reader.onload = (event) => {
            setImageDataURL(event.target.result);
        };
        reader.readAsDataURL(imageFile);
    };


    const saveGrz = (( values) => {
        if (!report.id) return;
        return new Promise((res,rej) => apvgkReportsService.saveGrz(report.id, {...values, img:file}).then((r) => {
            res(r);
            history.push(`${urlHelper.getHomeUrl(roleConstants.ROLE_APVGK)}/apvgk/`);
        }, rej))});


    return (
        <>
            <Link
                disabled={load}
                to={{}}
                onClick={() => {setOpen(true)}}
            >
                Передать на обработку
            </Link>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <Toolbar>
                    <Typography variant="title" color="inherit" className="flex">
                        Укажите номер ТС
                    </Typography>
                    <IconButton color="inherit" onClick={() => setOpen(false)}>
                        <CloseIcon className="blue"/>
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <Formik
                        initialValues={{grz:'', photo_ts:''}}
                        onSubmit={values => {
                            setLoad(true);
                            saveGrz(values).catch((e) => console.log(e)).finally(() => {
                                setLoad(false);
                            });
                            setOpen(false);
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        render={({values, setFieldValue}) => {
                            const finded = (finds.find((v) => v.vRegno === values.grz));
                            const photos = finded && finded.extraPhoto ? [finded].concat(finded.extraPhoto) : [];
                            return (
                                <Form>
                                    <Grid container direction="column" spacing={16} wrap="nowrap">
                                        <Grid item>
                                            <FieldLabel label="ГРЗ ТС" name="grz">
                                                <Field
                                                    name="grz"
                                                    type="text"
                                                    min="20"
                                                    component={MaterialSelect}
                                                    options={list}
                                                />
                                            </FieldLabel>
                                        </Grid>

                                        {!photos.length && <Grid item>
                                            {file && <FieldLabel label="Фото ТС" name="grz">
                                                {imageDataURL &&
                                                    <img src={imageDataURL} width="300px" alt="Pasted Image"/>}
                                            </FieldLabel>}
                                            <br/>
                                            <div className="paste-container"
                                                 ref={(ref) => {
                                                     if (ref) ref.addEventListener('paste', onPaste);
                                                 }}>
                                                Нажмите на этот текст и Ctrl+V для вставки изображения
                                            </div>
                                        </Grid> || ''}
                                        {photos.length  && <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                Выберите фотографию для добавления к акту
                                            </Grid>
                                            {photos.map(v => {
                                                return v.imageTypeId == GRZ_TYPE  ? '' : (<Grid item xs={4}>
                                                    <div
                                                        className={"photo-option" + (values.photo_ts === v.imageUrl && "-green" || "")}
                                                        onClick={() => setFieldValue('photo_ts', v.imageUrl)}>
                                                        <img src={result.s3 + v.imageUrl} alt="photo-image"
                                                             width="100%"/>
                                                    </div>
                                                </Grid>)
                                            }) || ''}
                                        </Grid> || ''}


                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="btn-add no-margin"
                                                type="submit"
                                                disabled={!(values.grz && (values.photo_ts || file))}
                                            >
                                                Передать
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default GrzSaveDialog;
