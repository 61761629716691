import { chain, flatten, isArray, isString } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import {apiService, handleUrl, prepareConfig} from './apiService';
import { apiConstants } from '../constants/apiConstants';
import { applicationConstants } from '../constants/applicationConstants';
import {getFile} from "../helpers/fileUploadHelper";

const getResourceName = () => {
  return 'apvgk_processing';
};


const getApvgkReports = (values) => {
  const status = values.status ? values.status : 0;
  const page = values.page ? values.page : 1;
  let params = {
      ...values,
      id: values.id && values.id.length > 0 ? values.id : null,
      IDBetamount: values.IDBetamount && values.IDBetamount.length > 0 ? values.IDBetamount : null,
      TrackStateNumber: values.TrackStateNumber && values.TrackStateNumber.length > 0 ? values.TrackStateNumber : null,
      user: values.user && values.user.length > 0 ? values.user : null,
      PlatformId: values.PlatformId && values.PlatformId.length > 0 ? values.PlatformId : null,
      min_date : values.min_date && values.min_date.replace('T', ' '),
      max_date : values.max_date && values.max_date.replace('T', ' '),
      max_speed : values.max_speed && values.max_speed || null,
      export: values.export ? 1 : 0,
      type: values.type,
      page
  }
  Object.keys(params).forEach((v) => {
      if (params[v] === null || params[v] === undefined) delete params[v];
  })
  if (params.export) {
      return apiService.downloadFile(`${apiConstants.API_URL}/${getResourceName()}/list/${status}?`+ (new URLSearchParams(params).toString()), `Выгрузка актов.xlsx`)
  }

  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/list/${status}`,
    params,
    true
  );
};


const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const getReasons= () => {
    return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/reject_list`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};


const toWorkMultiple = (values) => {
    return apiService.call(
        apiConstants.METHOD_POST,
        `/${getResourceName()}/set_work`,
        {ids: values},
        true
    );
};

const accept = (
  id,
  data
) => {
  let values = {
    ...(data.ecp || {}),
  };
  if (data.application_id !== undefined) values = {...values, application_id: data.application_id};
  if (data.sloppiness) {
    values.sloppiness = data.sloppiness;
  }
  if (data.categories) {
    values.categories = data.categories;
  }
  if (data.dimensions) {
    values.dimensions = data.dimensions;
  }
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/${id}/accept`,
    values,
    true
  );
};

const decline = values => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/${values.id}/reject`,
    values,
    true
  );
};
const checkGrz = (id, values) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/${id}/grz_check`,
    values,
    true
  );
};
const saveGrz = async (id, values) => {
    let data = new FormData();
    data.append('grz', values.grz)
    if (values.img) {
        let file = await getFile(values.img);
        data.append('file', file.plain)
    }
    data.append('photo_ts', values.photo_ts)
    return axios
        .post(handleUrl(`/${getResourceName()}/${id}/grz_save`), data, prepareConfig(true, {}))
};

const downloadFile = (id, openFile, query = '') => {
    return apiService.downloadFile(`${apiConstants.API_URL}/${getResourceName()}/${id}/pdf?${query}`, `Акт взвешивания ${id}.pdf`, openFile)
}
const downloadSigFile = (id, openFile, query = '') => {
    return apiService.downloadFile(`${apiConstants.API_URL}/${getResourceName()}/${id}/sig?${query}`, `Подпись акта взвешивания ${id}.sig`, openFile)
}
const downloadGrzFile = (id, openFile, query = '') => {
    const params = new URLSearchParams(query);
    return apiService.downloadFile(`${apiConstants.API_URL}/${getResourceName()}/${id}/grz-pdf?${query}`, `Отчетная форма распознавания ГРЗ ${params.grz} ${id}.pdf`, openFile)
}

const getStatistic = (filter) => {
    let params = {...filter};
    params.min_date = params.min_date.replace('T', ' ')
    params.max_date = params.max_date.replace('T', ' ')
    return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/statistic`, params, true);
};



export const apvgkReportsService = {
  getStatistic,
  getApvgkReports,
  getItem,
  create,
  update,
  delete: _delete,
  // admin
  accept,
  downloadFile,
  downloadGrzFile,
  downloadSigFile,
  decline,
  checkGrz,
  saveGrz,
  toWorkMultiple,
    getResourceName,
    getReasons,
};
