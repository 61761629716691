import React, { useState, Fragment } from 'react';
import ApvgkPanel from './ApvgkPanel';
import { apvgkService } from '../../../../services/apvgkService';
import ApvgkModal from './ApvgkModal';
import {apiService} from "../../../../services/apiService";
import {apiConstants} from "../../../../constants/apiConstants";

const ApvgkPanelContainer = (params) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [meta, setMeta] = useState({ per_page: 50 });
  const [showAddModal, setShowAddModal] = useState(false);
  const [addModalData, setAddModalData] = useState(null);
  const openAddModal = () => setShowAddModal(true);
  async function fetchPage(page) {
    setLoading(true);
    const response = await apvgkService.getPage(page, meta.per_page, true, params.type);
    setData(response.data);
    setMeta(response.meta);
    setLoading(false);
  }
  React.useEffect(() => {
    fetchPage(1);
  }, []);

  const onModalClose = newData => {
    if (!addModalData) {
      fetchPage(1);
    } else {
      setData(data.map(d => (d.id === newData.id ? newData : d)));
    }
    setShowAddModal(false);
  };

  const onDelete = async (id, perPage) => {
    await apvgkService.delete(id);
    fetchPage(meta.current_page);
  };

  const openEditModal = async item => {
    setAddModalData(item);
    setShowAddModal(true);
  };

  const onPageChange = page => {
    fetchPage(page);
  };

  return (
    <Fragment>
      <ApvgkPanel
        isLoading={isLoading}
        data={data}
        meta={meta}
        onPageChange={onPageChange}
        onAdd={openAddModal}
        onEdit={openEditModal}
        onDelete={onDelete}
      />
      <ApvgkModal isShow={showAddModal} onClose={onModalClose} initialData={addModalData} />
    </Fragment>
  );
};

export default ApvgkPanelContainer;
