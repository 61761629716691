import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Field, Form, Formik} from 'formik';
import MaterialInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput';
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import PhotoModal from "../../admin/track/PhotoModal";
import GrzCheckResultsDialog from "./GrzCheckResultsDialog";
import GrzSaveDialog from "./GrzSaveDialog";


const GrzCheckDialog = ({onCheck, report}) => {
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);

    let finds = (report.grz_from_post_result) || {};
    if (finds.hasOwnProperty('find')) finds = finds.find;
    return (
        <>
            <PhotoModal
                id={report.apvgk_violation.id}
                label="Фото c рамки"
            /><br/>
            <GrzCheckResultsDialog report={report}/>
            <Link
                disabled={load}
                to={{}}
                onClick={() => {setOpen(true)}}
            >
                Проверить {finds && finds.length ? ' еще раз':'номер на соседних комплексах'}
            </Link>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <Toolbar>
                    <Typography variant="title" color="inherit" className="flex">
                        Укажите скорость проверки
                    </Typography>
                    <IconButton color="inherit" onClick={() => setOpen(false)}>
                        <CloseIcon className="blue"/>
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <Formik
                        initialValues={{speed:60}}
                        onSubmit={values => {
                            setLoad(true);
                            onCheck(report, values).catch((e) => console.log(e)).finally(() => {
                                setLoad(false);
                            });
                            setOpen(false);
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        render={({values, setFieldValue}) => (
                            <Form>
                                <Grid container direction="column" spacing={16} wrap="nowrap">
                                    <Grid item>
                                        <FieldLabel label="Скорость движения ТС" name="speed">
                                            <Field
                                                name="speed"
                                                min="20"
                                                component={MaterialInput}
                                                type="number"
                                            />
                                        </FieldLabel>
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="btn-add no-margin"
                                            type="submit"
                                        >
                                            Проверить
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
            <br/>
            {finds && finds.length > 0 && <GrzSaveDialog report={report}/>}
        </>
    );
};

export default GrzCheckDialog;
